import * as React from "react";
import "./Card.css";
import { CheckboxConsumer } from  '../../utils/checkbox';
import { FaEye } from 'react-icons/fa';

type CardProps = {
    ticketType: string;
    ticket: Ticket;
};

type CardState = {
};

interface Ticket {
    id: string;
    priority: number;
    SLAtime: number;
    days: number;
    hours: number;
    isSLA: boolean;
    minutes: number;
    requestedby: string;
    reviewers: string[];
    assignee: string;
    total_seconds: number;
    percentExpired: number;
}

class Card extends React.Component<CardProps, CardState> {
    state = {
    }

    severityColorUpdater = (priority: number) => {
        if (priority <= 2) {
            return 'highSeverity';
        } else {
            return 'lowSeverity';
        }
    }

    getStateSLA = (total_seconds: number) => {
        if (total_seconds < 3600) { // within 1 hour
            return "warning";
        } else if (total_seconds < 7200) { // within 2 hours
            return "upcoming";
        } else if (total_seconds < 14400) { // within 4 hours
            return "later";
        } else if (total_seconds < 28800) { // within 8 hours
            return "later2";
        } else {
            return "future";
        }
    };

    getStateFTS = (days: number, hours: number) => {
        if (days === 0 && hours < 4) {
            return "later";
        } else if (days === 0 && hours < 8) {
            return "upcoming";
        } else {
            return "warning";
        }
    };

    getStateREV = (isSLA: boolean, SLAtime: number, days: number, hours: number, minutes: number) => {
        // if ticket is subject to first response SLA
        if (isSLA) {
            if (typeof SLAtime == 'number') {
                if (SLAtime < 1800) {
                    return "warning";
                } else if (SLAtime < 3600) {
                    return "upcoming";
                }
                return "later";
            }
        }
        if (hours >= 1 || days > 0) {
            return "warning";
        } else if (minutes > 30) {
            return "upcoming";
        } else {
            return "later";
        }
    };

    getStateUNA = () => {
        return "info";
    };

    getTime = (totalSeconds: number, days: number, hours: number, minutes: number) => {
        let time = '';

        if (typeof totalSeconds !== "undefined") {
            if (totalSeconds < 0) {
                return 'Missed';
            } else {
                let timeObj = {
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                };
    
                timeObj.days = Math.floor(totalSeconds / 86400);
                let remainingSeconds = totalSeconds - (timeObj.days * 86400);
                timeObj.hours = Math.floor(remainingSeconds / 3600);
                remainingSeconds -= timeObj.hours * 3600;
                timeObj.minutes = Math.floor(remainingSeconds / 60);
                timeObj.seconds = remainingSeconds - timeObj.minutes * 60;

                if (timeObj.days) {
                    time = timeObj.days + 'd ';
                }
                if (timeObj.hours) {
                    time = time + timeObj.hours + 'h ';
                } else {
                    time = time + '0h ';
                }
                if (timeObj.minutes) {
                    time = time + timeObj.minutes + 'm'
                }
                return time;
            }
        } else {
            if (days) {
                time = days + 'd ';
            }
            if (hours) {
                time = time + hours + 'h ';
            } else {
                time = time + '0h ';
            }
            if (minutes) {
                time = time + minutes + 'm'
            }
            return time;
        }
    }

    render() {
        const { ticket, ticketType } = this.props;
        // console.log(ticket);
        // console.log(`ticketType: ${ticketType}`);

        let id = ticket.id.startsWith('SUPPORT') 
            ? 'SUP-' + ticket.id.split('-')[1]
            : ticket.id;

        let isSLA = ticket.isSLA;
        let days = ticket.days;
        let hours = ticket.hours;
        let minutes = ticket.minutes;
        let priority = ticket.priority;
        let severity = priority ? 'S' + priority : '';
        let totalSeconds = ticket.total_seconds;
        let requester = ticket.requestedby ? ticket.requestedby : '';
        let reviewers = ticket.reviewers ? ticket.reviewers : [];
        let requesterFullName = "";
        if (requester.length > 0) {
            let firstName = requester.split(".")[0];
            let lastName = requester.split(".")[1];
            requesterFullName = firstName.charAt(0).toUpperCase() + firstName.slice(1) + " " + lastName.charAt(0).toUpperCase() + lastName.slice(1);
        }
        let reviewersList: string[] = [];
        let lowPriority = priority >= 3;
        if (reviewers.length > 0) {
            reviewers.map((name:string, keu:number) => {
                let firstName = name.split(".")[0];
                let lastName = name.split(".")[1];
                let fullName = firstName.charAt(0).toUpperCase() + firstName.slice(1) + " " + lastName.charAt(0).toUpperCase();
                reviewersList.push(fullName);
                return null;
            });
            // console.log(reviewersList);
        }
        let SLAtime = ticket.SLAtime;
        let assignee = ticket.assignee ? ticket.assignee : '';
        let severityColor = this.severityColorUpdater(priority);
        let ticketState: string;
        if (ticketType === 'SLA') {
            ticketState = this.getStateSLA(totalSeconds);
        } else if (ticketType === 'FTS') {
            ticketState = this.getStateFTS(days, hours);
        } else if (ticketType === 'REV') {
            ticketState = this.getStateREV(isSLA, SLAtime, days, hours, minutes);
        } else {
            ticketState = this.getStateUNA();
        }
        let SLATimeText = '';
        if (typeof SLAtime === 'number') {
            SLATimeText = this.getTime(SLAtime, days, hours, minutes);
        }
        
        let time = this.getTime(totalSeconds, days, hours, minutes);
   
        return (
            <CheckboxConsumer>
                {({ showAssignee, showLowPriority } : {showAssignee: boolean, showLowPriority: boolean}) => (
                    <div className={'list-group-item-' + ticketState + " card " + (lowPriority === true ? 'lowPriority' : '') + ' show-' + showLowPriority}>
                        <div className="cardHeader">
                            <span className={'severity ' + severityColor}>{severity}</span>
                            {reviewersList.length > 0 && <span className='reviewer'>{reviewersList}</span>}
                            {reviewersList.length > 0 && <span className='reviewer-icon'><FaEye size={18} /></span>}
                        </div>
                        <div className="cardBody">
                            <span className="ticketId">{id}</span>
                            <span className={`assignee show-` + showAssignee}>{assignee} {requesterFullName}</span>
                        </div>
                        <div className="cardFooter">
                            {
                                typeof SLAtime === 'number'
                                ? SLATimeText
                                : time
                            }
                        </div>
                    </div>
                )}
            </CheckboxConsumer>
        )
    }
}

export default Card;