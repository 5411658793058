import * as React from "react";
import { Stitch, GoogleRedirectCredential, UserApiKeyCredential, StitchUser } from 'mongodb-stitch-browser-sdk';
// import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';
import './App.css';
import Header from "../Header/Header";
import Board from "../Board/Board";
import Footer from "../Footer/Footer";
import { CheckboxProvider } from "../../utils/checkbox";
import { RemoteMongoClient } from "mongodb-stitch-browser-sdk";

// For deployment only, where config.js is available
declare var APP_CONFIG: any;

function optionalRequire() {
     try { return require('../../config.json'); } catch(e) {console.log(e); return false;}
 }
 const LOCAL_APP_CONFIG = optionalRequire();

type AppProps = {
  wallboardData ?: {};
  SLA?: [];
  FTS?: [];
  REV?: [];
  UNA?: [];
  activeCount?: number;
  waitingCount?: number;
}

export type AppState = {
  currentUser: StitchUser | undefined,
  wallboardData : {};
  currentSLA: [];
  currentFTS: [];
  currentREV: [];
  currentUNA: [];
  currActiveCount: number;
  currWaitingCount: number;
  last_sync_jira_dt: number;
  last_sync_sfdc_dt: number;
  showAssignee: boolean;
  showLowPriority: boolean;
  toggleAssignee: any;
  toggleLowPriority: any;
}


class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      currentUser: undefined,
      wallboardData: props.wallboardData || {},
      currentSLA: props.SLA || [],
      currentFTS: props.FTS || [],
      currentREV: props.REV || [],
      currentUNA: props.UNA || [],
      currActiveCount: props.activeCount || 0,
      currWaitingCount: props.waitingCount || 0,
      last_sync_jira_dt: 0,
      last_sync_sfdc_dt: 0,
      showAssignee: true,
      showLowPriority: true,
      toggleAssignee: () => {
        console.log("In App.tsx!");
        this.setState(({ showAssignee }) => ({
          showAssignee: showAssignee === true ? false : true
        }))
      },
      toggleLowPriority: () => {
        this.setState(({ showLowPriority }) => ({
          showLowPriority: showLowPriority === true ? false : true
        }))
      }
    }
  }

  componentDidMount() {
    // Hijack login for local testing This should be removed in prod.
    if (typeof LOCAL_APP_CONFIG !== 'undefined') {
        this._loadStitchClient(LOCAL_APP_CONFIG);
    } else {
        this._loadStitchClient(APP_CONFIG);
    }
  }

  async _loadStitchClient(config: any) {
    const appId = config.appId;

    // Get a client for your Stitch app, or instantiate a new one
    const client = Stitch.hasAppClient(appId)
      ? Stitch.getAppClient(appId)
      : Stitch.initializeAppClient(appId);
    
    // Manage user authentication state
    // Check if already authenticated then process the redirect to finish login
    if (client.auth.hasRedirectResult()) {
      await client.auth.handleRedirectResult().catch(console.error);
      console.log("Proceessed redirect result.");
    }

    if (client.auth.isLoggedIn) {
      // The user is logged in.
      const currentUser = client.auth.user;
      console.log(currentUser);
      this.setState({ currentUser });
      if (currentUser && currentUser.profile && typeof currentUser.profile.email !== 'undefined') {
        console.log(`logged into Stitch with ${currentUser.profile.email}!`);
      } else {
        console.log(`logged into Stitch with API key!`);
      }
      console.log(currentUser);
      this.getWallboardData(client);
    } else {
      // The user has not yet authenticated. Begin the Google login flow.
      console.log(`The user has not yet authenticated. Begin the login flow.`);

      if(process.env.REACT_APP_USER_KEY) {
           client.auth.loginWithCredential(new UserApiKeyCredential(process.env.REACT_APP_USER_KEY));
      } else {
          // Authenticate with Google OAuth (Production / Staging)
          const credential = new GoogleRedirectCredential(config.googleRedirectURL);
          client.auth.loginWithRedirect(credential);
      }
    }
  }

  async watcher(client : any) {

    const mongoClient = client.getServiceClient(
      RemoteMongoClient.factory,
      "mongodb-atlas"
    );
    
    const db_name = await client.callFunction("getSupportDBName");
    const hubCacheCollection = mongoClient.db(db_name).collection("hubCacheCollection");
    // Create a change stream that watches the collection
    const stream = await hubCacheCollection.watch([
          "currentWallBoard"
      ], {
        "fullDocument" : "updateLookup"
      });
    // Set up a change event handler function for the stream
    stream.onNext((event: any) => {
      // Handle the change events for all specified documents here
      console.log("Received case update, refreshing " + new Date());
      this.setState({wallboardData: event.fullDocument});
      this.setWallboardData(this.state.wallboardData);
    });
  }

  getWallboardData = (client: any) => {

    client.callFunction("getWallboardData").then((response: {}) => {
      this.setState({wallboardData: response});
      this.setWallboardData(this.state.wallboardData);
    }).catch((err: any) => {
        console.error(err);
    });

    try {
      // Watch the cache for updates and refresh the Wallboard
      this.watcher(client);

    } catch(e) {
      console.log(e);
    }
  }

  setWallboardData = (wallboardData: any) => {
    if (wallboardData) {
      this.setState({
        currentSLA: wallboardData.issues.SLA,
        currentFTS: wallboardData.issues.FTS,
        currentUNA: wallboardData.issues.UNA,
        currentREV: wallboardData.issues.REV,
        currActiveCount: wallboardData.issues.ACTIVE_COUNT,
        currWaitingCount: wallboardData.issues.WAITING_COUNT,
        last_sync_jira_dt: wallboardData.last_sync_jira_dt,
        last_sync_sfdc_dt: wallboardData.last_sync_sfdc_dt,
      })
    }
  }

  render() {
    const wallboardData = this.state.wallboardData;
    const { last_sync_jira_dt, last_sync_sfdc_dt } = this.state;

    return (
      <CheckboxProvider value={this.state}>
        <div className="App">
          <Header current_sync_jira_dt={last_sync_jira_dt} current_sync_sfdc_dt={last_sync_sfdc_dt}/>
          <Board ticketData={wallboardData}/>
          <Footer active={this.state.currActiveCount} waiting={this.state.currWaitingCount} ticketData={wallboardData} />
        </div>
      </CheckboxProvider>
    );
  }

}

export default App;
