import * as React from "react";
import "./Board.css";
import Carousel from "../Carousel/Carousel";

type BoardProps = {
    ticketData: any;
}

type BoardState = {
    wallboardData: any;
}

class Board extends React.Component<BoardProps, BoardState> {
    constructor(props: BoardProps) {
        super(props);
        this.state = {
            wallboardData: this.props.ticketData
        }
    }

    render() {
        let ticketIssues = this.props.ticketData.issues;

        let SLAIssues;
        let FTSIssues;
        let UNAIssues;
        let REVIssues;

        if (ticketIssues && ticketIssues['SLA']) {
            SLAIssues = ticketIssues['SLA'];
        } else {
            SLAIssues = {};
        }
        
        if (ticketIssues && ticketIssues['FTS']) {
            FTSIssues = ticketIssues['FTS'];
        } else {
            FTSIssues = {};
        }

        if (ticketIssues && ticketIssues['UNA']) {
            UNAIssues = ticketIssues['UNA'];
        } else {
            UNAIssues = {};
        }

        if (ticketIssues && ticketIssues['REV']) {
            REVIssues = ticketIssues['REV'];
        } else {
            REVIssues = {};
        }

        return (
            <div className="board">
                <Carousel ticketType="SLA" tickets={SLAIssues} />
                <Carousel ticketType="FTS" tickets={FTSIssues} />
                <Carousel ticketType="REV" tickets={REVIssues} />
                <Carousel ticketType="UNA" tickets={UNAIssues} />
            </div>
        )
    }
}

export default Board;
