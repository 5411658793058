import * as React from "react";
import "./Footer.css";
import Checkbox from '@leafygreen-ui/checkbox';
import { CheckboxConsumer } from  '../../utils/checkbox';

type FooterProps = {
    active: number;
    waiting: number;
    ticketData: any;
}

type FootState = {
    showAssignee: boolean,
    showLowPriority: boolean
}

class Footer extends React.Component<FooterProps, FootState> {
    state = {
        showAssignee: true,
        showLowPriority: true
    }

    render() {
        const { active, waiting } = this.props;

        return (
            <CheckboxConsumer>
                {({ showAssignee, showLowPriority, toggleAssignee, toggleLowPriority } : {showAssignee: boolean, showLowPriority: boolean, toggleAssignee: any, toggleLowPriority: any}) => (
                    <div className="footer">
                        <div className="footer-left">
                            <div className="footer-count">Active: {active}</div>
                            <div className="footer-count">Waiting: {waiting}</div>
                            <div className="footer-count">Total: {active + waiting}</div>
                        </div>
                        <div className="footer-right">
                            <div className="footer-show">
                                <span>Show: </span>
                            </div>
                            <div className="footer-checkbox">
                                <Checkbox className='assignee-checkbox' onChange={toggleAssignee} label='Assignee' checked={showAssignee} bold={true}/>
                                <Checkbox className='priority-checkbox' onChange={toggleLowPriority} label='Low Priority' checked={showLowPriority} bold={true}/>
                            </div>
                        </div>
                    </div>
                )}
            </CheckboxConsumer>
        )
    }
}

export default Footer;
