import React from 'react';
import './Clock.css';

export interface Props {}

interface State {
    date: Date;
}

class Clock extends React.Component<Props, State> {
    private timerID: any; // important to define type of custom field
    
    constructor(props: Props) {
        super(props);
        this.state = { date: new Date() };
    }

    // call after component is mounted / rendered to DOM
    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    // call before component is mounted / removed from DOM
    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: new Date()
        });
    }

    render() {
        let clockOptions = { 
            day: 'numeric', 
            month: 'short',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false
        };
    
        return (
            <div className="clock">
                {this.state.date.toLocaleString("en-US", clockOptions)}
            </div>
        )
    }
}

export default Clock;