import * as React from "react";
import "./Header.css";
import Clock from "../Clock/Clock";
import logo from "../../assets/images/logo-mongodb-leaf.png";

type HeaderProps = {
    current_sync_jira_dt: number;
    current_sync_sfdc_dt: number;
}

type HeaderState = {
    last_sync_jira_dt: number;
    last_sync_sfdc_dt: number;
    jiraDiff: number;
    // sfdcDiff: number;
}

class Header extends React.Component<HeaderProps, HeaderState> {
    state = {
        jiraDiff: 0,
        // sfdcDiff: 0,
        last_sync_jira_dt: new Date().valueOf(),
        last_sync_sfdc_dt: new Date().valueOf()
    }

    static getDerivedStateFromProps(props: HeaderProps, state: HeaderState) {
        const { current_sync_jira_dt, current_sync_sfdc_dt } = props;
        const { last_sync_jira_dt, last_sync_sfdc_dt } = state;

        if (current_sync_jira_dt !== last_sync_jira_dt || current_sync_sfdc_dt !== last_sync_sfdc_dt) {
            let now = new Date();
            let jiraLastSync = new Date(current_sync_jira_dt);
            // let sfdcLastSync = new Date(current_sync_sfdc_dt);
    
            let jiraDiff = Math.ceil((now.valueOf() - jiraLastSync.valueOf()) / 1000); // in seconds
            // let sfdcDiff = Math.ceil((now.valueOf() - sfdcLastSync.valueOf()) / 1000); // in seconds

            return {
                jiraDiff: jiraDiff,
                // sfdcDiff: sfdcDiff,
                last_sync_jira_dt: current_sync_jira_dt,
                last_sync_sfdc_dt: current_sync_sfdc_dt
            }
        }

        return null;
    }
    
    render() {
        const { jiraDiff/*, sfdcDiff*/} = this.state;
        return (
            <div className="header">
                <div className="header-left">
                    <div className="header-logo header-comp">
                        <img src={logo} className="logo" alt="logo" />
                    </div>
                    <div className="header-title header-comp">Support Wallboard</div>
                </div>
                <div className="header-right">
                    <div className="header-timer header-comp">
                        <Clock />
                    </div>
                    <div className="header-synctime">
                        {jiraDiff >= 30 && jiraDiff < 999999 && (
                            <span className={"jira-sync " + (jiraDiff < 60 && jiraDiff >= 30 ? "upcoming": "warning")}>
                                Last jira sync: {jiraDiff + 's'}
                            </span>
                        )}
                        {/* {sfdcDiff >= 30 && sfdcDiff < 999999 && (
                            <span className={"sfdc-sync " + (sfdcDiff < 60 && sfdcDiff >= 30 ? "upcoming": "warning")}>
                                Last sfdc sync: {sfdcDiff + 's'}
                            </span>
                        )} */}
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;