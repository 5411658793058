import * as React from "react";
import Measure from 'react-measure';
import "./Carousel.css";
import Badge from '@leafygreen-ui/badge';
import Card from "../Card/Card";
import { CheckboxConsumer } from "../../utils/checkbox";

type CarouselProps = {
    ticketType: string;
    tickets: Ticket[];
}

type CarouselState = {
    dimensions: any;
}

interface Ticket {
    SLAtime: number;
    days: number;
    hours: number;
    id: string;
    priority: number;
    isSLA: boolean;
    minutes: number;
    requestedby: string;
    reviewers: string[];
    assignee: string;
    total_seconds: number;
    percentExpired: number;
}

class Carousel extends React.Component<CarouselProps, CarouselState> {
    state = {
        dimensions: {
            width: -1,
            height: -1
        }
    }

    colorUpdater = (name: string) => {
        let colorCode: "yellow" | "red" | "green" | "blue" | "darkgray" | "lightgray" | undefined;
        if (name === "SLA") {
            colorCode = "yellow";
        } else if (name === "FTS") {
            colorCode = "red";
        } else if (name === "REV") {
            colorCode = "green";
        } else {
            colorCode = "blue";
        }

        return colorCode;
    }

    cardUpdater = (tickets: Ticket[], ticketType: string) => {
        let allCards: {"cards": any, "lastCard": any} = {"cards":'', "lastCard":''};
        let cards: {};
        let lastCard = {};
        const totalCards = Object.keys(tickets).length;
        const carouselWidth = this.state.dimensions.width;
        // console.log(`width: ${carouselWidth}`);

        let expectedCards = Math.floor(carouselWidth / 178) === totalCards 
            ? Math.floor(carouselWidth / 178)
            : Math.floor(carouselWidth / 178) - 1;
        // console.log(`total cards: ${expectedCards}`);

        // Object.keys(obj) returns an array of keys
        // Array.map() returns a new array of returned value from function
        cards = Object.keys(tickets).map((ticket:any, key:number) => {
            if (key < expectedCards) {
                let data = tickets[ticket];
                return <Card ticket={data} key={key} ticketType={ticketType}/>
            }
            return null;
        })

        lastCard = Object.keys(tickets).map((ticket:any, key:number) => {
            if (key === expectedCards) {
                let ticketInfo = tickets[ticket]
                return this.lastCardTimer(ticketInfo.total_seconds, ticketInfo.days, ticketInfo.hours, ticketInfo.minutes);
            } return null;
        })

        allCards["cards"] = cards;
        allCards["lastCard"] = lastCard;

        return allCards;
    }

    lastCardTimer = (totalSeconds: number, days: number, hours: number, minutes: number) => {
        let time = '';

        if (typeof totalSeconds !== "undefined") {
            if (totalSeconds < 0) {
                return 'Missed';
            } else {
                let timeObj = {
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                };
    
                timeObj.days = Math.floor(totalSeconds / 86400);
                let remainingSeconds = totalSeconds - (timeObj.days * 86400);
                timeObj.hours = Math.floor(remainingSeconds / 3600);
                remainingSeconds -= timeObj.hours * 3600;
                timeObj.minutes = Math.floor(remainingSeconds / 60);
                timeObj.seconds = remainingSeconds - timeObj.minutes * 60;

                if (timeObj.days) {
                    time = timeObj.days + 'd ';
                }
                if (timeObj.hours) {
                    time = time + timeObj.hours + 'h ';
                } else {
                    time = time + '0h ';
                }
                if (timeObj.minutes) {
                    time = time + timeObj.minutes + 'm'
                }
                return time;
            }
        } else {
            if (days) {
                time = days + 'd ';
            }
            if (hours) {
                time = time + hours + 'h ';
            } else {
                time = time + '0h ';
            }
            if (minutes) {
                time = time + minutes + 'm'
            }
            return time;
        }
    }

    render() {
        const { ticketType, tickets } = this.props;
        const carouselWidth = this.state.dimensions.width;
        const totalCards = Object.keys(tickets).length;
        let expectedCards = Math.floor(carouselWidth / 178) === totalCards 
            ? Math.floor(carouselWidth / 178)
            : Math.floor(carouselWidth / 178) - 1;
        let cards: any;
        let allCards: any;

        if (tickets) {
            allCards = this.cardUpdater(tickets, ticketType);
            cards = allCards["cards"];
        } else {
            allCards = {}
        }
        let colorCode = this.colorUpdater(ticketType);
        let overflow = false;
        if (totalCards > expectedCards) {
            overflow = true;
        }

        return (
            <CheckboxConsumer>
                {({ showLowPriority } : {showLowPriority: boolean}) => (
                    <Measure 
                        bounds 
                        onResize={contentRect => {
                            this.setState({ 
                                dimensions: contentRect.bounds
                            })
                        }}
                    >
                        {({ measureRef  }) => (
                            <div className="carousel">
                                <div className="carousel-header">
                                    <h1>{ticketType}</h1>
                                    <Badge variant={colorCode} className='count-badge'>
                                        {tickets.length}
                                    </Badge>
                                </div>
                                <div className={ticketType + ' carousel-body'} ref={measureRef}>
                                    {cards}
                                    {overflow === true && showLowPriority === true && (
                                        <div className="card wrapperCard">
                                            <div className="lastCardCount">+{totalCards - expectedCards}</div>
                                            <div className="lastCardTime">
                                                <span>{allCards["lastCard"]}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Measure>
                )}
            </CheckboxConsumer>
            
        )
    }
}

export default Carousel;